import Footer from "../../layouts/footer"
import Header from "../../layouts/header"
import img1 from "../../assets/images/Credit-Rebuilder-Logo2.png";
import f1 from "../../assets/images/course3_reason1.png";
import f2 from "../../assets/images/course3_reason2.png";
import f3 from "../../assets/images/course3_reason3.png";
import f4 from "../../assets/images/course3_reason4.png";
import f5 from "../../assets/images/course3_reason5.png";
import f6 from "../../assets/images/course3_reason6.png";
import p1 from "../../assets/images/profile.png";
import p2 from "../../assets/images/p2.png";
import p3 from "../../assets/images/p3.jpeg";
import p4 from "../../assets/images/p4.png";
import p5 from "../../assets/images/p5.png";
import p6 from "../../assets/images/p6.png";
import p7 from "../../assets/images/p7.jpeg";
import p8 from "../../assets/images/p8.jpeg"
import cross from "../../assets/images/cross.png";
import { Link } from "react-router-dom";
import { useState } from "react";
const Creditrebuilder = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'When will my payment be reported to the credit bureaus?',
      answers: [
        {
          answer: 'Your first $39 payment will be reported to the credit bureau the month after it is made. For example:If your first $39 monthly payment is made in February, it will be reported in March.'
        },
        {
          answer: 'If you pay the setup fee in February and your first $39 monthly payment in March, it will be reported in April.'
        },
        {
          answer: 'Please note that credit reporting does not happen immediately and typically takes 30-60 days from your initial enrollment date. If you have questions about your payment schedule, feel free to reach out for clarification.'
        },
        {
          answer: ''
        },
        {
          answer: 'Payments will be reported to your credit report by Consolidation Capital.'
        },
      ]

    },
    {
      question: 'When will my credit score start to increase?',
      answers: [
        {
          answer: "Credit scores typically begin to improve after 4-6 months of consistent payments. However, results vary based on your individual credit history."
        }
      ]
    },


    {
      question: 'I’m confused: The program says it’s month-to-month, but it also says that it is 24 months. Which one is it?',
      answers: [
        {
          answer: "The program is month-to-month, allowing you to cancel at any time. However, full participation for 24 months is the default and is recommended to fully benefit from the program and achieve significant credit improvement."
        },
        {
          answer: "If your credit score improves sooner, great! Contact us, and we will cancel your Credit Rebuilder Program at any time, without penalty."
        },
      ]
    },

    {
      question: 'Where can I pull my credit report?',
      answers: [
        {
          answer: "As part of your membership, you will receive a free review of your credit report. Our representatives will guide you through the process and show you exactly how to access your credit report for free."
        },
      ]
    },
    {
      question: 'When will my enrollment into 7 Steps to a 720 Credit Score begin?',
      answers: [
        {
          answer: "Your membership will begin as soon as your account has been set up, which is generally within 72 hours of enrollment."
        },
      ]
    },
    {
      question: 'What if I am already enrolled in 7 Steps to a 720 Credit Score?',
      answers: [
        {
          answer: "Great! If you received 7 Steps to a 720 Credit Score through our partnership with Evergreen Financial Counseling, your membership will continue uninterrupted. Plus, you'll receive all the other benefits of the Credit Rebuilder Program."
        },
      ]
    },
    {
      question: 'What if there are two people in my family who would like to enroll?',
      answers: [
        {
          answer: "Each person will need to sign up individually."
        },
      ]
    },
    {
      question: 'Will the payments be automatically deducted from my account?',
      answers: [
        {
          answer: "Yes. When you completed the agreement, you will enter your payment information. We set up the automatic payments from our end so you do not have to worry about making your payments on time."
        },
      ]
    },
    {
      question: 'What is the interest rate?',
      answers: [
        {
          answer: "The interest rate on this program is 0%."
        },
      ]
    },
    {
      question: 'Will my credit be pulled for this program?',
      answers: [
        {
          answer: "No, we will not pull your credit. 100% of our clients will be approved, regardless of their credit score or income."
        },
      ]
    },

    {
      question: 'What if I already have an installment account in good standing on my credit report?',
      answers: [
        {
          answer: "If you already have an installment account being reported to your credit report, then you may not need this program. To help you decide, consider these questions: Is your existing installment account in good standing? Is your credit score improving as fast as you want it to? Will this payment cause a financial hardship for you? If you answered yes to any of these questions (and particularly the last one) please do not enroll unless you are in need of the other membership benefits!"
        },
      ]
    },
    {
      question: 'When will I receive my loan?',
      answers: [
        {
          answer: "This is not a loan. You will not receive any money by joining this program. By enrolling in the Credit Rebuilding Program, you will pay a monthly fee in exchange for: 1) Lifetime access to 7 Steps to a 720 Credit Score; 2) Priority access to our monthly Q&A calls; 3) Qualified legal support and counseling; 4) Platinum review of your credit report; 5) Unemployment benefits (if qualified); and 6) Debt analysis. Your monthly payment will be reported to the credit bureaus as an installment account."
        },
      ]
    },
  ];
  return (
    <div>
      <Header />
      <div className="space_for_website_top"></div>
      <section className="crbldrSec1">
        <div className="container container__small">
          <h1 className="green crbldrSec1__heading">A Fast Path to Great Credit</h1>
          <p className="crbldrSec1__para">Without doing anything else, your credit score will improve—perhaps as much as 140 points—simply by joining this program.</p>
          <h6 className="crbldrSec1__heading2">Month-to-Month Committment, Cancel Anytime.</h6>
          <div className="crbldrSec1__wrapper d-flex">
            <div className="crbldrSec1__col1">
              <img src={img1} alt="feature" className="" />
            </div>
            <div className="crbldrSec1__col2">
              <div className="crbldrSec1__listBox">
                <h1 className="crbldrSec1__listBox__heading">
                  Unlock Better Credit Today!
                </h1>
                <ul className="crbldrSec1__listBoxList">
                  <span className="crbldrSec1__listBoxList__span">Designed for people who want fast results.</span>
                  <li className="crbldrSec1__listBoxList__item">Everyone is approved. </li>
                  <li className="crbldrSec1__listBoxList__item">Your on-time payments improve your credit score within 6 months. </li>
                  <li className="crbldrSec1__listBoxList__item">We remove all errors from your credit report.   </li>
                  <span className="crbldrSec1__listBoxList__span crbldrSec1__listBoxList__span--2">Plus, you will receive: </span>
                  <li className="crbldrSec1__listBoxList__item">Free Fair Credit Reporting legal counsel</li>
                  <li className="crbldrSec1__listBoxList__item">Free debt analysis </li>
                  <li className="crbldrSec1__listBoxList__item">Free access to our credit-education program</li>
                  <a href="https://form.typeform.com/to/Uyms4Fwd" className="flex-a rbldrSec1__listBoxList__linkBtn" rel="noreferrer" target="_blank">ENROLL NOW</a>
                </ul>
              </div>
            </div>
          </div>
          <div className="couterNumber__wrapper d-flex">
            <div className="crbldrSec1__col1">
              <div className="couterNumberBoxHolder">
                <p className="crbldrSec1__col1__desc">Your credit score will improve simply by joining this program.</p>
                <div className="d-flex couterNumberBox">
                  <div className="couterNumber flex-a">1</div>
                  <p className="couterNumberBox__para">
                    Your payments for this program are reported as an installment line of credit and have the same positive impact on your credit score as on-time car payments.
                  </p>
                </div>
                <div className="d-flex couterNumberBox">
                  <div className="couterNumber flex-a">2</div>
                  <p className="couterNumberBox__para">
                    Correcting errors will significantly increase your credit score. And, better yet, if the creditors or credit bureaus are violating your rights with respect to the errors on your credit report, you might be eligible for financial compensation, in which case we will provide free legal support and fight for your rights.
                  </p>
                </div>
              </div>

            </div>
            <div className="crbldrSec1__col2">
              <div className="questionCallBox">
                <h1 className="questionCallBox__heading">Questions? Call us! </h1>
                <a href="tel:800-720-6886" className="questionCallBox__btn flex-a">800-720-6886</a>
              </div>
              <div className="pricingbox">
                <h1 className="pricingbox__heading">$39/month</h1>
                <h2 className="pricingbox__subHeading">+$99 set-up fee</h2>
                <h3 className="pricingbox__subHeading2">Cancel Anytime</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="credit-comparison-sec">
        <div className="container container__small">
          <div className="credit-comparison">
            <div className="credit-comparison__column">
              <div className="credit-comparison__header credit-comparison__header--green">
                <h2 className="credit-comparison__title">7 Steps to a 720 Credit Score</h2>
              </div>

              <div className="credit-comparison__content">
                <p className="credit-comparison__text">Do it yourself</p>

                <p className="credit-comparison__text">Upon completion of Courses 1 and 2</p>
                <div className="credit-comparison__text">
                  <img
                    src={cross}
                    alt="Cross"
                    className="credit-comparison__cross-icon"
                  /> <br />
                </div>
                <div className="credit-comparison__text">
                  <img
                    src={cross}
                    alt="Cross"
                    className="credit-comparison__cross-icon"
                  />
                </div>
                <div className="credit-comparison__text">
                  <img
                    src={cross}
                    alt="Cross"
                    className="credit-comparison__cross-icon"
                  />
                </div>

                <p className="credit-comparison__text">One-time review of your credit report <br /> <br /> <br /></p>

                <div className="credit-comparison__text">
                  <img
                    src={cross}
                    alt="Cross"
                    className="credit-comparison__cross-icon"
                  /> <br /> <br /><br /> <br /><br /> <br /><br /> <br /><br /> <br /><br /> <br />
                </div>

                <p className="credit-comparison__text">Enrollment into the DIY version of 7 Steps to a 720 Credit Score</p>

                <p className="credit-comparison__text">Support during monthly Q&A credit education sessions <br /> <br /></p>

                <p className="credit-comparison__text">Automatic enrollment 30 days after completing Course #2 <br /> <br /></p>

                <a href="https://www.evergreenclass.com/720course" rel="noreferrer" target="_blank" className="credit-comparison__button credit-comparison__button--green">
                  Take a Sneak Peak at 7 Steps to 720 Credit Course
                </a>
              </div>
            </div>

            <div className="credit-comparison__column">
              <div className="credit-comparison__header credit-comparison__header--yellow">
                <h2 className="credit-comparison__title">Credit Rebuilder Program Upgrade. </h2>
              </div>

              <div className="credit-comparison__content">
                {/* <p className="credit-comparison__text">We do it for you</p> */}
                <p className="credit-comparison__text">Our upgrade</p>


                <Link href="#" className="credit-comparison__text credit-comparison__text--link d-flex">Start Today!</Link>

                <p className="credit-comparison__text">Your payments are reported as "in good standing" to the credit bureaus (a potential 80 gain!)</p>

                <p className="credit-comparison__text">Free debt analysis</p>

                <p className="credit-comparison__text">Involuntary unemployment protection</p>

                <p className="credit-comparison__text">
                  Unlimited Full Platinum Credit Report Review: We remove all errors from your credit report (a potential 60 point increase). PLUS, we will assist you with future errors.
                </p>

                <p className="credit-comparison__text">
                  Priority legal counsel when necessary. <br /> <br />

                  We review for violations of the Fair Credit Reporting Act and, when appropriate, file a lawsuit on your behalf. <br /> <br />

                  We review for violations of the Fair Debt Collections Act and, when appropriate, file a lawsuit on your behalf. <br /> <br />

                  We review for violations of the Fair Credit Billing Act and, when appropriate, file a lawsuit on your behalf. <br /> <br />

                  Is your bankruptcy being reported correctly? If not, we will provide legal support.</p>


                <p className="credit-comparison__text">Lifetime access to 7 Steps to a 720 Credit Score <br /><br /></p>

                <p className="credit-comparison__text">Priority support during monthly Q&A credit education sessions</p>

                <p className="credit-comparison__text">$39/month for 24 months Or, cancel when you reach your credit-score goal. $99 setup fee</p>

                <button className="credit-comparison__button credit-comparison__button--yellow">
                  Join the Credit Rebuilder Program
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="secDevider">
        <div className="container container__small">
          <h1 className="secDevider__heading">More Reasons to Enroll in the Credit Rebuilder Program</h1>
        </div>
      </div>
      <section className="featuresSec">
        <div className="container container__small">
          <div class="features">
            <div class="feature">
              <div class="feature__icon">
                <img src={f1} alt="feature" className="feature__iconimg" />
              </div>
              <h3 class="feature__title">
                No Credit Checks
              </h3>
              <p class="feature__description">
                Everyone is approved, so you can start your journey to better credit without worrying about your current credit score impacting eligibility.
              </p>
            </div>
            <div class="feature">
              <div class="feature__icon">
                <img src={f2} alt="feature" className="feature__iconimg" />
              </div>
              <h3 class="feature__title">
                Priority Support
              </h3>
              <p class="feature__description">
                You will receive top-tier support and priority access to credit experts during monthly Q&A sessions.
              </p>
            </div>
            <div class="feature">
              <div class="feature__icon">
                <img src={f3} alt="feature" className="feature__iconimg" />
              </div>
              <h3 class="feature__title">
                Free Debt Analysis
              </h3>
              <p class="feature__description">
                One of our licensed consumer law attorneys will analyze your debt and help you develop strategies for financial freedom.
              </p>
            </div>
            <div class="feature">
              <div class="feature__icon">
                <img src={f4} alt="feature" className="feature__iconimg" />
              </div>
              <h3 class="feature__title">
                Involuntary Unemployment Protection
              </h3>
              <p class="feature__description">
                Continue your membership without disruption, even during periods of unexpected unemployment.
              </p>
            </div>
            <div class="feature">
              <div class="feature__icon">
                <img src={f5} alt="feature" className="feature__iconimg" />
              </div>
              <h3 class="feature__title">
                Legal and Consumer Protection
              </h3>
              <p class="feature__description">
                If lenders or credit bureaus have broken laws in collecting or reporting your debt, you will receive free legal advice and consumer protection counsel. In fact, if any laws have been broken, our attorneys may help you receive financial compensation.
              </p>
            </div>
            <div class="feature">
              <div class="feature__icon">
                <img src={f6} alt="feature" className="feature__iconimg" />
              </div>
              <h3 class="feature__title">
                Lifetime Access and Updates
              </h3>
              <p class="feature__description">
                The 7 Steps to a 720 Credit Education Course included in your membership breaks down the exact rules for building a high credit score in just 12 to 24 months.
              </p>
            </div>
          </div>
          <div class="cta flex-a">
            <a href="https://form.typeform.com/to/Uyms4Fwd" target="_blank" class="cta__button flex-a">ENROLL NOW</a>
          </div>
        </div>
      </section>
      <div className="secDevider">
        <div className="container container__small">
          <h1 className="secDevider__heading">What Will a Higher Credit Score Do for You?</h1>
        </div>
      </div>
      <section className="comment-section">
        <div className="container container__small">
          <div className="commentCard_wrapper">
            <div className="comment-card">
              <p className="comment-text">
                So far, my credit score has increased at least 100 points; I purchased a car, and I got three credit cards!
              </p>
              <div className="d-flex flex-i">
                <img className="comment-image" src={p1} alt="Barbara Bonney" />
                <div className="comment-content">
                  <h3 className="comment-name">Barbara Bonney</h3>
                  <p className="comment-location">Troy, MO</p>
                </div>
              </div>
            </div>
            <div className="comment-card">
              <p className="comment-text">
                Two years after my bankruptcy, my credit scores are 712 and 709! Now, I have two credit cards with $1,000 limits.
              </p>
              <div className="d-flex flex-i">
                <img className="comment-image" src={p2} alt="profile" />
                <div className="comment-content">
                  <h3 className="comment-name">
                    Brian Overby
                  </h3>
                  <p className="comment-location">
                    Newark, DE
                  </p>
                </div>
              </div>
            </div>
            <div className="comment-card">
              <p className="comment-text">
                At first I was a bit skeptical about the course, but my score increased about 150 points, and my credit card limits all went up.to 735!
              </p>
              <div className="d-flex flex-i">
                <img className="comment-image" src={p3} alt="profile" />
                <div className="comment-content">
                  <h3 className="comment-name">
                    Nicholas Castillo
                  </h3>
                  <p className="comment-location">
                    Cypress, TX
                  </p>
                </div>
              </div>
            </div>
            <div className="comment-card">
              <p className="comment-text">
                I was able to raise my credit score by 150 points within 3 months of filing bankruptcy!
              </p>
              <div className="d-flex flex-i">
                <img className="comment-image" src={p4} alt="profile" />
                <div className="comment-content">
                  <h3 className="comment-name">

                    Desirae Richardson
                  </h3>
                  <p className="comment-location">
                    Springfield, OH
                  </p>
                </div>
              </div>
            </div>
            <div className="comment-card">
              <p className="comment-text">
                This year I bought a new car with no down payment or co-signer, all because of my credit score!
              </p>
              <div className="d-flex flex-i">
                <img className="comment-image" src={p5} alt="profile" />
                <div className="comment-content">
                  <h3 className="comment-name">
                    Jasmine Addison
                  </h3>
                  <p className="comment-location">
                    Katy, TX
                  </p>
                </div>
              </div>
            </div>
            <div className="comment-card">
              <p className="comment-text">
                After my bankruptcy in March
                of 2019, my credit score jumped
                to 735!
              </p>
              <div className="d-flex flex-i">
                <img className="comment-image" src={p6} alt="profile" />
                <div className="comment-content">
                  <h3 className="comment-name">
                    Anne Kluskie
                  </h3>
                  <p className="comment-location">
                    Syracuse, NY
                  </p>
                </div>
              </div>
            </div>
            <div className="comment-card comment-card--half">
              <p className="comment-text">
                I didn&apos;t know anything about the credit system, but when I filed my third bankruptcy, my attorney made me join this program. Now, my credit score is right around 720, and I no longer feel helpless and ashamed. I encourage anybody who has had a history of problems with their money to GET EDUCATED!
              </p>
              <div className="d-flex flex-i">
                <img className="comment-image" src={p7} alt="profile" />
                <div className="comment-content">
                  <h3 className="comment-name">
                    Geraldine Crippen
                  </h3>
                  <p className="comment-location">
                    Albuquerque, NM
                  </p>
                </div>
              </div>
            </div>
            <div className="comment-card comment-card--half">
              <p className="comment-text">
                With a credit score of 780 just five years after my bankruptcy, I closed on my first home: A three-bedroom rambler on seven acres with enough room for my dog and my horse. It&apos;s an amazing feeling, and something I thought would never happen, but it did. It never would have happened without the help of the 720 credit score program.
              </p>
              <div className="d-flex flex-i">
                <img className="comment-image" src={p8} alt="profile" />
                <div className="comment-content">
                  <h3 className="comment-name">
                    Susan Brown
                  </h3>
                  <p className="comment-location">
                    Cambridge, MN
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="secDevider">
        <div className="container container__small">
          <h1 className="secDevider__heading">Frequently Asked Questions</h1>
        </div>
      </div>
      <section className="faqSec">
        <div className="container container__small">
          <div className="faq">
            {faqData.map((item, index) => (
              <div
                key={index}
                className={`faq__item ${activeIndex === index ? 'faq__item--active' : ''}`}
              >
                <div className="faq__question" onClick={() => toggleAccordion(index)}>
                  {item.question}
                  <span className="faq__icon">
                    {activeIndex === index ? '-' : '+'}
                  </span>
                </div>
                <div className={` ${activeIndex === index ? 'pt-3' : ''}`}>
                  {activeIndex === index && (
                    item.answers.map((q) => {
                      return <div className="faq__answer">{q.answer}</div>
                    })
                  )}
                </div>

              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="secDevider">
        <div className="container container__small">
          <h1 className="secDevider__heading">About the 7 Steps to a 720 Credit Score Program</h1>
        </div>
      </div>
      <section className="about7steps">
        <div className="container container__small">
          <div className="about7steps__text">If you have been through a bankruptcy, you will automatically be enrolled in <a href="https://www.evergreenclass.com/720course" className="about7steps__text__link" rel="noreferrer" target="_blank">7 Steps to a 720 Credit Score </a>  upon completing Course #1 and Course #2 through <a href="https://www.evergreenclass.com/" className="about7steps__text__link" rel="noreferrer" target="_blank">Evergreen Financial Counseling</a> .   Otherwise,  7 Steps to a 720 Credit Score is available as part of the Credit Rebuilder Program. </div>
          <div class="cta cta--about7steps flex-a">
            <a href="https://form.typeform.com/to/Uyms4Fwd" rel="noreferrer" target="_blank" class="cta__button cta__button--about7steps  flex-a">Enroll in the Credit Rebuilder Program Now</a>
          </div>
        </div>

      </section>
      <Footer />
    </div>
  )
}

export default Creditrebuilder