
import Footer from "../../layouts/footer"
import Header from "../../layouts/header"
import logo from "../../assets/images/resource-hero.png"
import { useState } from "react"
import { Link } from "react-router-dom";
import "./EvergreenFinancialHome.css"
import BankruptcyCard from "./BankruptcyCard";
import { FaChevronRight, FaCreditCard, FaCar, FaChevronUp, FaChevronDown } from "react-icons/fa"
import { Card, Button } from "react-bootstrap";
import plusIcon from "../../assets/images/SiteImg/plus.png";
import minusIcon from "../../assets/images/SiteImg/white-minus-icon.png";

const Resources = () => {
  const [answers, setAnswers] = useState({
    inDebt: null,
    needBankruptcy: null,
    outOfDebt: null,
    rebuildCredit: null,
    needVehicle: null,
    readyForHomeownership: null,
  })
  const [isExpanded1, setIsExpanded1] = useState(false);
  const toggleContent1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const [activeTab, setActiveTab] = useState("how-we-help");
  const [currentQuestion, setCurrentQuestion] = useState("inDebt")
  const [showResult, setShowResult] = useState(null)

  const handleAnswer = (question, answer) => {
    setAnswers((prev) => ({ ...prev, [question]: answer }))

    if (question === "inDebt") {
      if (answer === true) {
        setShowResult("debtYes")
        setCurrentQuestion(null)
      } else {
        setCurrentQuestion("needBankruptcy")
        setShowResult(null)
      }
    } else if (question === "needBankruptcy") {
      if (answer === true) {
        setShowResult("bankruptcyYes")
        setCurrentQuestion(null)
      } else {
        setCurrentQuestion("outOfDebt")
        setShowResult(null)
      }
    } else if (question === "outOfDebt") {
      if (answer === true) {
        setShowResult("outOfDebtYes")
        setCurrentQuestion(null)
      } else {
        setCurrentQuestion("rebuildCredit")
        setShowResult(null)
      }
    } else if (question === "rebuildCredit") {
      if (answer === true) {
        setShowResult("rebuildCreditYes")
        setCurrentQuestion(null)
      } else {
        setCurrentQuestion("needVehicle")
        setShowResult(null)
      }
    } else if (question === "needVehicle") {
      if (answer === true) {
        setShowResult("needVehicleYes")
        setCurrentQuestion(null)
      } else {
        setCurrentQuestion("readyForHomeownership")
        setShowResult(null)
      }
    } else if (question === "readyForHomeownership") {
      if (answer === true) {
        window.location.href = "https://www.evergreenclass.com/mortgage"
      } else {
        setShowResult("wealthBuilding")
        setCurrentQuestion(null)
      }
    }
  }

  const resetQuestionnaire = () => {
    setAnswers({
      inDebt: null,
      needBankruptcy: null,
      outOfDebt: null,
      rebuildCredit: null,
      needVehicle: null,
      readyForHomeownership: null,
    })
    setCurrentQuestion("inDebt")
    setShowResult(null)
  }

  const handleSendMail = async () => {
    console.log("send mail to support@720creditscore.com.")
  }
  const handleConnectWithCouncelor = async () => {
    console.log("Connect me with a professional")
  }
  const handleCardClick = (cardTitle) => {
    console.log(`Card clicked: ${cardTitle}`)
  }
  const sectionStyle = {
    textAlign: "center",
    padding: "2rem",
  };

  const tabsContainerStyle = {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#f8f9fa", // Light gray (Bootstrap's default gray background)
    padding: "0.5rem",
    borderRadius: "0.5rem",
    maxWidth: "600px",
    margin: "0 auto",
  };

  const tabButtonStyle = {
    margin: "0 0.25rem",
  };

  const tabContentStyle = {
    marginTop: "1.5rem",
    padding: "1rem",
    backgroundColor: "#fff",
    borderRadius: "0.5rem",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    margin: "1rem auto 0",
    color: "#495057",
  };
  return (
    <>
      <Header />

      <main>

        <section className="financialRec">
          <div className="container d-flex" >
            <div className="col-lg-6">

              <h1 className="f-52" style={{ fontSize: '48px' }}>
                Resources for Your <span>Financial Comeback</span>
              </h1>
              <div className="">
                <p className="pt-1"
                  style={{ fontSize: '16px' }}

                >
                  Too often, people struggling with financial hardship are told what they can&apos;t do. They hear they
                  can&apos;t get a car loan, can&apos;t buy a home, can&apos;t rebuild after a setback.
                </p>


                <h1 className="f-52 mt-5" style={{ fontSize: '24px' }}>
                  But we know the truth: <span>you can.</span>
                </h1>


                <p className="pt-1"
                  style={{ fontSize: '16px' }}
                >
                  Whether you’re facing debt, considering bankruptcy, or working to rebuild your credit, we believe in you. Our goal is simple: to guide you from financial distress to financial stability—and then beyond. Because stability isn’t the finish line. Our hope is that you build wealth.
                </p>


              </div>
            </div>
            <div className="col-lg-6 d-flex financialRec-logo " style={{ justifyContent: 'center', alignItems: 'center' }}>
              <img src={logo} alt="Image here" className="financialRec__logo"
                style={{ height: '85%', width: '70%' }}
              />
            </div>
          </div>
        </section>
        {/* <section className=" eg-text-center">
          <p className="financial__text pt-5" style={{ width: '85%', margin: 'auto' }}>
            That journey starts with education, resources, and support from professionals who understand what you&apos;re going through.
          </p>

          <div className="eg-button-container">
            <a href="#how-we-help" className="btn_brnd">
              How We Help
            </a>
            <a href="#video-resources" className="btn_brnd">
              Watch & Learn
            </a>
            <a href="#next-step" className="btn_brnd">
              Determine Your Next Step
            </a>
          </div>
        </section> */}


        <section className="tabs-section">
          <div className="tabs-container">
            <button
              type="button"
              className={`btn ${activeTab === "how-we-help" ? "btn_brnd" : "btn_brnd_inactive"
                }`}
              onClick={() => setActiveTab("how-we-help")}
            >
              How We Help
            </button>

            <button
              type="button"
              className={`btn ${activeTab === "video-resources" ? "btn_brnd" : "btn_brnd_inactive"
                }`}
              onClick={() => setActiveTab("video-resources")}
            >
              Watch &amp; Learn
            </button>

            <button
              type="button"
              className={`btn ${activeTab === "next-step" ? "btn_brnd" : "btn_brnd_inactive"
                }`}
              onClick={() => setActiveTab("next-step")}
            >
              Determine Your Next Step
            </button>
          </div>

          {/* <div className="tab-content">
        {activeTab === "how-we-help" && (
          <p>
            Learn about the various ways we provide support and resources to help
            you navigate your financial journey.
          </p>
        )}
        {activeTab === "video-resources" && (
          <p>
            Watch educational videos that guide you through financial recovery
            and smart decision-making.
          </p>
        )}
        {activeTab === "next-step" && (
          <p>
            Find out the next best step in your financial recovery journey with
            personalized guidance.
          </p>
        )}
      </div> */}
        </section>

        {activeTab === "how-we-help" && (
          <section id="how-we-help" className="" style={{ marginTop: '1rem' }}>
            <h2 className="eg-title eg-text-center">HOW WE HELP</h2>
            <div className="eg-content-wide" >
              <p className="financial__text " style={{ width: '80%', textAlign: 'center', fontSize: '16px' }}>
                In addition to our credit counseling and debtor-education classes, Evergreen Financial Counseling provides expert guidance in:
              </p>

              <div className="">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="eg-card-content p-0">
                      <div className="d-flex align-items-center mb-3">
                        <FaCreditCard className="eg-icon-lg text-primary me-3" />
                        <h3 className="eg-card-title mb-0">Credit Education</h3>
                      </div>

                      <div className="space-y-3 rounded-lg bg-primary/5 px-4 " style={{ borderRadius: '30px', margin: '0px 0px' }}>
                        <div className=" d-flex" style={{ gap: '15px' }}>


                          <p className="" style={{ width: '80%', fontSize: '16px' }}>
                            Understand your financial picture and explore your options for getting out of debt with the Credit
                            Rebuilder Program.
                          </p>

                          <a href="https://evergreenclass.com/creditrebuilder"
                            target="_blank"
                            style={{ height: '40px', display: 'flex', alignItems: 'center' }}


                            className="btn_brnd"
                          >
                            Learn More

                          </a>
                        </div>
                        <div className=" d-flex" style={{ gap: '15px' }}>
                          <p className="" style={{ width: '80%', fontSize: '16px' }}>
                            Plus, all students who complete the debtor-education course through Evergreen will be enrolled
                            free of charge in 7 Steps to a 720 Credit Score, courtesy of your attorney.
                          </p>
                          <a
                            href="https://www.evergreenclass.com/720course"
                            target="_blank"
                            style={{ height: '40px', display: 'flex', alignItems: 'center' }}

                            className="btn_brnd"
                          >
                            Learn More
                          </a>

                        </div>
                        <Card style={{ border: "unset", padding: '10px 20px 20px' }} className="media_object">
                          <Card.Body>

                            <div className="">
                              <div className="d-flex" style={{ gap: '60px' }}>
                                <Card.Title as="h4" className="">
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      fontStyle: "normal",
                                      fontWeight: "700",
                                      color: "#0c1922",
                                    }}
                                  >
                                    7 Steps to a 720 Credit Score :
                                  </span>


                                </Card.Title>
                                <Button
                                  variant={isExpanded1 ? "#4E9C4B " : ""}
                                  size="sm"
                                  onClick={toggleContent1}
                                  style={{
                                    // borderRadius: "50%",
                                    // width: "30px",
                                    // height: "30px",
                                    // display: "inline-flex",
                                    // alignItems: "center",
                                    // justifyContent: "center",
                                    // marginRight: "10px",
                                    // border: "1px solid green",
                                    boxShadow: "unset",
                                    // backgroundColor: isExpanded1 ? "#4E9C4B" : "",
                                  }}
                                >
                                  {isExpanded1 ? (
                                    // <img src={minusIcon} alt="minus-icon" />
                                    <FaChevronDown />
                                  ) : (
                                    // <img src={plusIcon} alt="plus-icon" />
                                    <FaChevronUp />
                                  )}
                                </Button>
                              </div>

                              {isExpanded1 && (
                                <Card.Text>
                                  <p className="text-sm" style={{ marginTop: '8px', fontSize: '16px' }}>
                                    As part of your enrollment in 7 Steps to a 720 Credit Score, the 7 Steps team will connect you with a law
                                    firm who will review your credit report for free. If they find errors, the law firm will help you dispute
                                    the errors—and they may even represent you if your rights have been violated under the Fair Credit Reporting
                                    Act, a set of laws that protects debtors' credit reports.
                                  </p>

                                  <b className="font-medium text-sm " >This is all included in your 7 Steps enrollment.</b>

                                  <p className="text-xs italic text-muted-foreground mt-2" style={{ background: '#F9FAFB', padding: '15px', borderRadius: '20px', fontSize: '16px' }}>
                                    <b>Note: </b> Credit report reviews are facilitated through participating law firms and may not be available in all
                                    cases. If you haven't been enrolled and would like to be,
                                    <Link href="#" className="text-primary hover:underline ml-1">
                                      click here
                                    </Link>{" "}
                                    to request enrollment.
                                  </p>
                                </Card.Text>
                              )}
                            </div>
                          </Card.Body>
                        </Card>

                      </div>



                    </div>
                  </div>
                </div>
              </div>
              <div className=" mb-5 mt-2">
                <div className="eg-card-header p-0">
                  <div className="d-flex align-items-center">
                    <FaCar className="eg-icon-lg text-primary me-3" />
                    <h3 className="eg-card-title mb-0">Automobile Education</h3>
                  </div>
                  <p className="mt-3 mb-0 pb-2">
                    Learn how to buy a car responsibly, even after bankruptcy. Know what lenders look for and how to get the
                    best deal.
                  </p>
                </div>

                <div className="eg-card-content p-0 media_object">
                  <div className="row" style={{ padding: '20px 0px', justifyContent: 'center' }}>
                    <div className="col-lg-6 mb-2 mb-lg-0">
                      <div
                        className="eg-video-container"
                        style={{ overflow: "hidden", borderRadius: "12px", display: 'flex', justifyContent: 'center' }}
                      >
                        {/* <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/9xwazD5SyVg"
                          title="Chapter 7 Bankruptcy Car Buying Guide"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          style={{ borderRadius: "12px", display: "block" }}
                        ></iframe> */}

                        <iframe
                          width="100%"
                          height="315"
                          src={`https://www.youtube.com/embed/${new URLSearchParams(new URL("https://www.youtube.com/watch?v=vnahgkO43C4").search).get("v")}`}
                          title="Chapter 7 Bankruptcy Car Buying Guide"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          style={{ borderRadius: "12px", display: "block" }}
                          allowFullScreen
                        />
                      </div>

                    </div>

                    <div className="col-lg-5 order-lg-2 mb-4 mb-lg-0" style={{ display: 'flex', alignItems: 'center' }}>

                      <div className="eg-content-box d-flex flex-column justify-content-center mt-3">
                        <h4 className="eg-subtitle mb-3" style={{ fontWeight: '700' }}>Chapter 7 Bankruptcy</h4>
                        <p className="mb-3" style={{ fontSize: '16px' }}>
                          If you need a vehicle after a Chapter 7 bankruptcy and aren't sure how bankruptcy affects your
                          options, check out this video.
                        </p>
                      </div>


                    </div>
                  </div>
                </div>

                <div className="eg-card-content p-0 media_object">
                  <div className="row" style={{ padding: '20px 0px', justifyContent: 'center' }}>
                    <div className="col-lg-5 mb-2 mb-lg-0"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div className="eg-content-box  d-flex flex-column justify-content-center mt-3"

                      >
                        <h4 className="eg-subtitle mb-3" style={{ fontWeight: '700' }}>Chapter 13 Bankruptcy</h4>
                        <p className="mb-3" style={{ fontSize: '16px' }}>
                          If you're in a Chapter 13 bankruptcy and need a vehicle, this video will walk you through what to
                          do next.
                        </p>

                        <p className="mb-3">
                          <span className="text-primary mr-2">
                            {`Learn more `}
                          </span>
                          about buying a car after a Chapter 13 bankruptcy

                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 order-lg-2 mb-4 mb-lg-0">
                      <div
                        className="eg-video-container"
                        style={{ overflow: "hidden", borderRadius: "12px", display: 'flex', justifyContent: 'center' }}
                      >
                        {/* <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/9xwazD5SyVg"
                          title="Chapter 7 Bankruptcy Car Buying Guide"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          style={{ borderRadius: "12px", display: "block" }}
                        ></iframe> */}
                        <iframe
                          width="100%"
                          height="315"
                          src={`https://www.youtube.com/embed/${new URLSearchParams(new URL("https://www.youtube.com/watch?v=ux8ZkScvvT4").search).get("v")}`}
                          title="Chapter 13 Bankruptcy"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          style={{ borderRadius: "12px", display: "block" }}
                          allowFullScreen
                        />

                      </div>

                    </div>
                  </div>
                </div>

                <div className="eg-card-footer p-4 text-center">
                  <p className="eg-font-medium mb-0">
                    No matter where you are today, there's a way forward. And you don't have to figure it out alone.
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}

        {activeTab === "video-resources" && (
          <section id="video-resources" className="" style={{ marginTop: '1rem' }}>
            <h2 className="eg-title eg-text-center">WATCH & LEARN VIDEO RESOURCES</h2>
            <div className="eg-content-wide">
              <p className="eg-mb" style={{ width: '80%', textAlign: 'center', fontSize: '16px' }}>
                We know financial struggles can feel overwhelming. That's why we've created video resources to break
                down key topics in a way that's easy to understand. Whether you're recovering from bankruptcy, worried
                about debt, or trying to plan your next financial step, these videos are here to help.
              </p>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mt-2">
                <div className="col ">
                  <BankruptcyCard
                    title="Will Bankruptcy Eliminate All Your Debt?"
                    description="The truth about what bankruptcy can—and can’t—do.A lot of people assume bankruptcy wipes out everything. Others assume bankruptcy won’t help at all.The reality is somewhere in between."
                    buttonText="I want more"
                    videoSrc={"https://www.youtube.com/watch?v=WQaQvP3csU0&t=4s"}
                    onClick={() => handleCardClick("Will Bankruptcy Eliminate All Your Debt?")}
                    card="1"
                  />
                </div>

                <div className="col">
                  <BankruptcyCard
                    title="How to Buy a Car DURING Chapter 7 Bankruptcy (and Where to Get It)"
                    description="Bankruptcy doesn’t mean you can’t get a car—it means you need the right strategy.A common misconception is that once you file for bankruptcy, lenders won’t work with you. The truth? Filing for bankruptcy doesn’t have to be a roadblock in the way of buying a new car. "
                    buttonText="I want more"
                    videoSrc={"https://www.youtube.com/watch?v=vnahgkO43C4"}
                    onClick={() => handleCardClick("Buying a Car During Chapter 7")}
                    card="2"

                  />
                </div>

                <div className="col">
                  <BankruptcyCard
                    title="How to Buy a Car DURING Chapter 13 Bankruptcy (and Where to Get It)"
                    description="Unlike Chapter 7, Chapter 13 requires court approval before you take on new debt—including a car loan. That doesn’t mean it’s impossible; it just means you need to do it the right way."
                    buttonText="I want more"
                    videoSrc={"https://www.youtube.com/watch?v=ux8ZkScvvT4"}
                    onClick={() => handleCardClick("Buying a Car During Chapter 13")}
                    card="3"

                  />
                </div>
              </div>
            </div>
          </section>
        )}

        {activeTab === "next-step" && (
          <section id="next-step" className="" style={{ marginTop: '1rem' }}>
            <h2 className="eg-title eg-text-center">DETERMINE YOUR NEXT STEP</h2>
            <div className="eg-content-max">
              <div className="eg-text-center eg-mb">
                <p className="eg-text-xl eg-font-medium eg-mb-sm">
                  Financial hardship doesn't define you. What you do next does.
                </p>
                <p className="eg-mb" style={{ fontSize: '16px' }}>
                  At Evergreen Financial Counseling, we're here to help you move forward with confidence. Whether you
                  need education, guidance, or professional connections, we've got your back.
                </p>
                <p style={{ fontSize: '16px' }}>The right next step depends on where you are in your wealth-building journey.</p>
              </div>

              <div className="eg-card">
                <div className="eg-card-content eg-pt">

                  <div className="eg-questionnaire">

                    {currentQuestion === "inDebt" && (
                      <div>
                        <h3 className="eg-question">Are you in debt?</h3>
                        <div className="eg-button-group">
                          <button onClick={() => handleAnswer("inDebt", true)} className="btn_brnd">
                            Yes
                          </button>
                          <button onClick={() => handleAnswer("inDebt", false)} className="btn_brnd-outline" style={{ borderRadius: '15px' }}>
                            No
                          </button>
                        </div>
                      </div>
                    )}


                    {currentQuestion === "needBankruptcy" && (
                      <div>
                        <h3 className="eg-question">
                          Have you decided to declare bankruptcy and need an introduction to a bankruptcy attorney?
                        </h3>
                        <div className="eg-button-group">
                          <button
                            onClick={() => handleAnswer("needBankruptcy", true)}
                            className="btn_brnd"
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => handleAnswer("needBankruptcy", false)}
                            className="btn_brnd-outline" style={{ borderRadius: '15px' }}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Out of Debt Question */}
                    {currentQuestion === "outOfDebt" && (
                      <div>
                        <h3 className="eg-question">
                          Are you out of debt and ready to start learning how to stay out of debt so that you can build
                          wealth?
                        </h3>
                        <div className="eg-button-group">
                          <button
                            onClick={() => handleAnswer("outOfDebt", true)}
                            className="btn_brnd"
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => handleAnswer("outOfDebt", false)}
                            className="btn_brnd-outline" style={{ borderRadius: '15px' }}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Rebuild Credit Question */}
                    {currentQuestion === "rebuildCredit" && (
                      <div>
                        <h3 className="eg-question">
                          Have you taken both the credit-counseling course and the debtor-education course and want to
                          start rebuilding your credit score?
                        </h3>
                        <p className="eg-mb">
                          Do you need to learn more about budgeting? If so, take our debtor-education course.
                        </p>
                        <div className="eg-button-group">
                          <button
                            onClick={() => handleAnswer("rebuildCredit", true)}
                            className="btn_brnd"
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => handleAnswer("rebuildCredit", false)}
                            className="btn_brnd-outline" style={{ borderRadius: '15px' }}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Need Vehicle Question */}
                    {currentQuestion === "needVehicle" && (
                      <div>
                        <h3 className="eg-question">Are you in the middle of a bankruptcy and need a vehicle?</h3>
                        <div className="eg-button-group">
                          <button
                            onClick={() => handleAnswer("needVehicle", true)}
                            className="btn_brnd"
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => handleAnswer("needVehicle", false)}
                            className="btn_brnd-outline" style={{ borderRadius: '15px' }}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Ready for Homeownership Question */}
                    {currentQuestion === "readyForHomeownership" && (
                      <div>
                        <h3 className="eg-question">
                          Are you on the other side of a bankruptcy and ready to start learning about homeownership?
                        </h3>
                        <div className="eg-button-group">
                          <a
                            href="https://www.evergreenclass.com/mortgage"
                            className="btn_brnd"
                            onClick={() => handleAnswer("readyForHomeownership", true)}
                          >
                            Yes
                          </a>
                          <button
                            onClick={() => handleAnswer("readyForHomeownership", false)}
                            className="btn_brnd-outline" style={{ borderRadius: '15px' }}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Results */}
                    {showResult === "debtYes" && (
                      <div className="eg-result">
                        <p className="eg-mb">
                          Start by taking the credit-counseling course, which will help you explore your options for
                          eliminating debt so that you can build wealth.
                        </p>
                        <div className="eg-button-group">
                          <Link
                            to={`/registration`}
                            className="btn_brnd"
                          >
                            Sign up for the course
                          </Link>
                          <button onClick={resetQuestionnaire} className="btn_brnd-outline" style={{ borderRadius: '15px' }}>
                            Start Over
                          </button>
                        </div>
                      </div>
                    )}

                    {showResult === "bankruptcyYes" && (
                      <div className="eg-result">
                        <p className="eg-mb">
                          Your next step is to determine whether you qualify for bankruptcy. We can connect you with a
                          professional who can conduct a free debt analysis to see if you qualify for bankruptcy.
                        </p>
                        <div className="eg-button-group">
                          <Link
                            to={`#`}
                            className="btn_brnd"
                          >
                            Book an appointment
                          </Link>
                          <button onClick={resetQuestionnaire} className="btn_brnd-outline" style={{ borderRadius: '15px' }}>
                            Start Over
                          </button>
                        </div>
                      </div>
                    )}

                    {showResult === "outOfDebtYes" && (
                      <div className="eg-result">
                        <p className="eg-mb">Your next step is to take the debtor-education course.</p>
                        <div className="eg-button-group">
                          <Link
                            to={`/registration`}
                            className="btn_brnd"
                          >
                            Sign up for the course
                          </Link>
                          <button onClick={resetQuestionnaire} className="btn_brnd-outline" style={{ borderRadius: '15px' }}>
                            Start Over
                          </button>
                        </div>
                      </div>
                    )}

                    {showResult === "rebuildCreditYes" && (
                      <div className="eg-result">
                        <p className="eg-mb">
                          Great. You'll be enrolled in 7 Steps to a 720 Credit Score approximately 30 days after
                          completing the debtor-education course.
                        </p>
                        <p className="eg-mb">Or, you can enroll in the Credit Rebuilder Program and start today.</p>
                        <div className="eg-button-stack">
                          <button href="#" className="btn_brnd-outline eg-full-width" style={{ borderRadius: '15px' }}>
                            I'll wait to be enrolled in 7 Steps to a 720 Credit Score
                          </button>
                          <Link
                            to="https://www.evergreenclass.com/creditrebuilder"
                            className="btn_brnd-outline eg-full-width"
                            style={{ borderRadius: '15px' }}
                          >
                            I'll join the Credit Rebuilder Program and fast-track my success
                          </Link>
                          <button
                            onClick={handleSendMail}
                            className="btn_brnd-outline eg-full-width"
                            style={{ borderRadius: '15px' }}
                          >
                            It's been 30 days since I took the debtor-education course, and I haven't been enrolled in 7
                            Steps to a 720 Credit Score
                          </button>
                          <button
                            onClick={resetQuestionnaire}
                            className="btn_brnd-outline eg-full-width eg-mt"
                            style={{ borderRadius: '15px' }}
                          >
                            Start Over
                          </button>
                        </div>
                      </div>
                    )}

                    {showResult === "needVehicleYes" && (
                      <div className="eg-result">
                        <p className="eg-mb">
                          Great. We'll connect you with a professional who can educate you on your options.
                        </p>
                        <div className="eg-button-group">
                          <button className="btn_brnd" onClick={handleConnectWithCouncelor}>
                            Connect me with a professional
                          </button>
                          <button onClick={resetQuestionnaire} className="btn_brnd-outline" style={{ borderRadius: '15px' }}>
                            Start Over
                          </button>
                        </div>
                      </div>
                    )}

                    {showResult === "wealthBuilding" && (
                      <div className="eg-result">
                        <h3 className="eg-result-title">
                          Fantastic. Your next step is to start learning about building your wealth. Here are some
                          resources to get you started.
                        </h3>
                        <div className="eg-button-stack">

                          <button
                            onClick={resetQuestionnaire}
                            className="btn_brnd-outline eg-full-width eg-mt"
                            style={{ borderRadius: '15px' }}
                          >
                            Start Over
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

      </main>


      <Footer />
    </>
  )
}

export default Resources