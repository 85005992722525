import { useState } from "react";
import { IoChevronDown, } from "react-icons/io5";
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
const CarpFAQsSec = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="finantial_faq pb-0">
      <div className="container container__fnedu2">
        <div className="row finantial_faq__row">
          <div className="col-lg-12">
            <div className="finantial_faq__heading finantial_faq__heading--crpfaqs">
              <p className="finantial_faq__tag finantial_faq__tag--crpfaqs">FAQs</p>
              <h2 className="finantial_faq__title finantial_faq__title--crpfaqs">
                Frequently <br /> asked questions
              </h2>
            </div>
          </div>

          <div className="col-12">
            <div className="finantial_faq__content">
              {/* FAQ 1 */}
              <div className="finantial_faq__item">
                <div className={`finantial_faq__question ${openIndex === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
                  <span className="finantial_faq__question-holder">
                    When are monthly payments due?
                  </span>
                  <span className="finantial_faq__icon">
                    {openIndex === 1 ? "" : <IoChevronDown className="faq__icons" />}
                  </span>
                </div>
                {openIndex === 1 && (
                  <div className="finantial_faq__answer">
                    Your monthly payment is due by the last day of the month. Payments are reported to the credit bureaus the following month.
                  </div>
                )}
              </div>

              {/* FAQ 2 */}
              <div className="finantial_faq__item">
                <div className={`finantial_faq__question ${openIndex === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
                  <span className="finantial_faq__question-holder">
                    When will my first payment be reported to the credit bureaus?
                  </span>
                  <span className="finantial_faq__icon">
                    {openIndex === 2 ? "" : <IoChevronDown className="faq__icons" />}
                  </span>
                </div>
                {openIndex === 2 && (
                  <ul className="finantial_faq__itemList">
                    <li className="finantial_faq__answer">
                      It can take up to 100 days for the credit bureaus to reflect your initial $39 payment.
                    </li>
                    <li className="finantial_faq__answer">If the 100-day mark falls mid-month, please allow until the end of that month for updates to appear.</li>
                    <li className="finantial_faq__answer">For example, your January, February, and March payments should appear on your credit report no later than the end of April. Each month after, your report will update with your payment history (e.g., your April payment will reflect by the end of May).</li>
                    <div className="finantial_faq__answer">
                      <b>Note: </b>  If you have questions about your payment schedule, feel free to contact support at
                      <a href="tel:800-720-6886" rel="noopener noreferrer"> <IoMdCall /> (800) 720-6886 </a>  or by
                      <a href="mailto:pricilla@evergreenclass.com" rel="noopener noreferrer"> {" "}
                        <MdEmail /> Email
                      </a>. </div>
                  </ul>
                )}
              </div>

              {/* FAQ 3 */}
              <div className="finantial_faq__item">
                <div className={`finantial_faq__question ${openIndex === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
                  <span className="finantial_faq__question-holder">
                    How can I update my payment information?
                  </span>
                  <span className="finantial_faq__icon">
                    {openIndex === 3 ? "" : <IoChevronDown className="faq__icons" />}
                  </span>
                </div>
                {openIndex === 3 && (
                  <div className="finantial_faq__answer">
                    Update your payment information <Link to="https://api.leadconnectorhq.com/widget/form/vWEEIzjPYTrO8B1YUS0k" target="_blank">here</Link>.
                  </div>
                )}
              </div>

              {/* FAQ 4 */}
              <div className="finantial_faq__item">
                <div className={`finantial_faq__question ${openIndex === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
                  <span className="finantial_faq__question-holder">
                    I am going to be late on my payment. What should I do?
                  </span>
                  <span className="finantial_faq__icon">
                    {openIndex === 4 ? "" : <IoChevronDown className="faq__icons" />}
                  </span>
                </div>
                {openIndex === 4 && (
                  <div className="finantial_faq__answer">
                    If you anticipate a late payment, please contact us at <a href="tel:800-720-6886" rel="noopener noreferrer"><IoMdCall /> (800) 720-6886 </a>  before your due date so we can discuss your options.
                    Making late payments may delay your credit score improvements.
                  </div>
                )}
              </div>

              {/* FAQ 5 */}
              <div className="finantial_faq__item">
                <div className={`finantial_faq__question ${openIndex === 5 ? "active" : ""}`} onClick={() => toggleFAQ(5)}>
                  <span className="finantial_faq__question-holder">
                    Why aren&apos;t I seeing the payments on my credit report?
                  </span>
                  <span className="finantial_faq__icon">
                    {openIndex === 5 ? "" : <IoChevronDown className="faq__icons" />}
                  </span>
                </div>
                {openIndex === 5 && (
                  <div className="finantial_faq__answer">
                    Payments are reported by Consolidation Capital and typically appear within 100 days of your enrollment. If more than 100 days have passed and you still don&apos;t see the updates, contact support for assistance.
                  </div>
                )}
              </div>

              {/* FAQ 6 */}
              <div className="finantial_faq__item">
                <div className={`finantial_faq__question ${openIndex === 6 ? "active" : ""}`} onClick={() => toggleFAQ(6)}>
                  <span className="finantial_faq__question-holder">
                    When will my credit score start to increase?
                  </span>
                  <span className="finantial_faq__icon">
                    {openIndex === 6 ? "" : <IoChevronDown className="faq__icons" />}
                  </span>
                </div>
                {openIndex === 6 && (
                  <div className="finantial_faq__answer">
                    Your payments will be reported to the credit bureaus by Capital Consolidation to help build positive payment history. Initially, you may notice a slight dip in your credit score when the account opens, but with consistent, on-time payments, and by completing the 7 Steps to a 720 Credit Score program, your score will  start to improve over time.
                  </div>
                )}
              </div>

              {/* FAQ 7 */}
              <div className="finantial_faq__item">
                <div className={`finantial_faq__question ${openIndex === 7 ? "active" : ""}`} onClick={() => toggleFAQ(7)}>
                  <span className="finantial_faq__question-holder">
                    The program says it&apos;s month-to-month, but also mentions 24 months. I&apos;m confused.
                  </span>
                  <span className="finantial_faq__icon">
                    {openIndex === 7 ? "" : <IoChevronDown className="faq__icons" />}
                  </span>
                </div>
                {openIndex === 7 && (
                  <div className="finantial_faq__answer">
                    <p>

                    The Credit Rebuilder Program is month-to-month, meaning you can cancel at any time. However, we recommend participating for 24 months to maximize credit score improvements.
                    </p>
                    <p class="oval-box">
                      To cancel, contact us at:
                      <a href="tel:800-720-6886" rel="noopener noreferrer" >
                        <IoMdCall /> (800) 720-6886
                      </a>
                      or by
                      <a href="mailto:pricilla@evergreenclass.com" rel="noopener noreferrer" >
                        <MdEmail /> Email
                      </a>.
                      <b style={{display:'block',paddingTop:'5px'}}>
                    Please Note that canceling prior to your fourth payment might hurt your score.
                    </b>
                    </p>
                  
                    
                   
                  </div>

                )}
              </div>
              {/* FAQ 8 */}
              <div className="finantial_faq__item">
                <div className={`finantial_faq__question ${openIndex === 8 ? "active" : ""}`} onClick={() => toggleFAQ(8)}>
                  <span className="finantial_faq__question-holder">
                    Where can I pull my credit report?
                  </span>
                  <span className="finantial_faq__icon">
                    {openIndex === 8 ? "" : <IoChevronDown className="faq__icons" />}
                  </span>
                </div>
                {openIndex === 8 && (
                  <div className="finantial_faq__answer">
                    When you <Link to="https://calendly.com/fcra/free-review-of-your-credit-report-credit-rebuilder-clients" target="_blank">schedule your platinum credit review here</Link>, the law firm representative will guide you through the process and show you exactly how to access your credit report.

                  </div>
                )}
              </div>

              {/* FAQ 9 */}
              <div className="finantial_faq__item">
                <div className={`finantial_faq__question ${openIndex === 9 ? "active" : ""}`} onClick={() => toggleFAQ(9)}>
                  <span className="finantial_faq__question-holder">
                    I have a question that hasn&apos;t been answered.
                  </span>
                  <span className="finantial_faq__icon">
                    {openIndex === 9 ? "" : <IoChevronDown className="faq__icons" />}
                  </span>
                </div>
                {openIndex === 9 && (
                  <div className="finantial_faq__answer">
                    If you have a question about your specific credit report, please attend the next Q&A session. These sessions occur monthly. You will receive an invitation by email, and you will be given an opportunity to submit your questions in advance. Credit Rebuilder Program clients have priority access, meaning their questions will be answered first. <br />
                    <br />
                    If you have a general question about the Credit Rebuilder Program, please contact the support team. <br />

                    <a href="tel:800-720-6886" rel="noopener noreferrer"> <IoMdCall /> (800) 720-6886 </a> <br />
                    <a href="mailto:pricilla@evergreenclass.com" rel="noopener noreferrer"> {" "}
                      <MdEmail /> Email
                    </a>

                  </div>
                )}
              </div>

              {/* Add remaining FAQs similarly */}
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default CarpFAQsSec;
